
import { GENRE_CHOICES, TARGET_AUDIENCE_CHOICES, PREFECTURE_CHOICES, IS_INSTRUCTOR_CHOICES, AUTO_BOOK_CHOICES } from "../constants/dropdownChoices";
import { INSTRUCTOR_LESSON_STATUS_CHOICES } from "../constants/dropdownChoices";

export const displayGenre = (value: string, language: 'en' | 'jp'): string => {
  const genre = GENRE_CHOICES.find(genre => genre.en === value);
  return genre ? genre[language] : value;
};

export const displayTargetAudience = (value: string, language: 'en' | 'jp'): string => {
  const target_audience = TARGET_AUDIENCE_CHOICES.find(t => t.en === value);
  return target_audience ? target_audience[language] : value;
};

export const displayPrefecture = (value: string, language: 'en' | 'jp'): string => {
  const prefecture = PREFECTURE_CHOICES.find(t => t.en === value);
  return prefecture ? prefecture[language] : value;
};

export const displayIsInstructor = (value: boolean, language: 'en' | 'jp'): string => {
  const isInstructor = IS_INSTRUCTOR_CHOICES.find(t => t.value === value);
  return isInstructor ? isInstructor[language] : '';
}

export const displayAutoBook = (value: boolean, language: 'en' | 'jp'): string => {
  const autoBook = AUTO_BOOK_CHOICES.find(t => t.value === value);
  return autoBook ? autoBook[language] : '';
}


// active, in-progress, inactive, draft
export const displayInstructorLessonStatus = (value: string, language: 'en' | 'jp'): string => {
  const status = INSTRUCTOR_LESSON_STATUS_CHOICES.find(t => t.en === value);
  return status ? status[language] : '';
}



