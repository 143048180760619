
//returns true or false
export const validateEmail= (email: string): boolean => {
    let re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return re.test(email)
}



export const checkOlderThan = (date:Date, ageLimit: number): boolean => {
    const currentDate = new Date();
    const birthYear = date.getFullYear();
    const currentYear = currentDate.getFullYear();

    let age = currentYear - birthYear;

    if (currentDate.getMonth() < date.getMonth() || 
        (currentDate.getMonth() === date.getMonth() &&
            currentDate.getDate() < date.getDate())
    ) {
        age--;
    }
    return age > ageLimit;
}