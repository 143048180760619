//packages
import React,{useEffect, useState, useRef} from 'react';
import { useRouter } from 'next/router';

//react icons
import { IoSearch } from "react-icons/io5";
import { MdOutlineBookmarkBorder } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { LuMessageSquare } from "react-icons/lu";
import { PiGearBold } from "react-icons/pi";


//redux
import { useAppSelector } from '@/src/hooks/useSelector';


interface UserMobileNavFooterProps    {
}

export const UserMobileNavFooter = ({
}: UserMobileNavFooterProps) => {
    const router = useRouter();
    const currentUrl = router.asPath    
    const {action} = router.query
    //language translation settings
    const l = useAppSelector(state => state.settings).data.language


    const userData = useAppSelector(state => state.user).data
    const {user, basic_profile, user_profile, instructor_profile} = userData
    const {token} = user




    /**
     * TEXT TRANSLATIONS
    */
    const text:any = {
        lessons:{
            en:'Lessons',
            jp:'レッスン'
        },
        following:{
            en:'Following',
            jp:'フォロー中'
        },
        bookings:{
            en:'Bookings',
            jp:'予約'
        },
        messages:{
            en:'Messages',
            jp:'メッセージ'
        },
        settings:{
            en:'Settings',
            jp:'設定'
        },
    }




    return (
        <div className={`sticky bottom-0 bg-white w-full h-[70px] drop-shadow-sm flex items-center relative border-t`}>
            <nav className='flex flex-row h-full w-full justify-between items-center'>

                {/** NAV ITEMS */}
                <div
                    onClick={() => router.push('/search-lessons')} 
                    className={`w-full h-full flex flex-col gap-1 items-center justify-center cursor-pointer pt-1
                    ${currentUrl.includes('/search-lessons') ? 'text-orange-600' : 'text-gray-500'}
                `}>
                    <IoSearch size={20} className=''/>
                    <p className='text-sm'>{text['lessons'][l]}</p>
                </div>
                <div
                    onClick={() => router.push('/user/following')} 
                    className={`w-full h-full flex flex-col gap-1 items-center justify-center cursor-pointer pt-1
                    ${currentUrl.includes('/user/following') ? 'text-orange-600' : 'text-gray-500'}
                `}>
                    <MdOutlineBookmarkBorder size={20} className=''/>
                    <p className='text-sm'>{text['following'][l]}</p>
                </div>
                <div
                    onClick={() => router.push('/user/reservations')} 
                    className={`w-full h-full flex flex-col gap-1 items-center justify-center cursor-pointer pt-1
                    ${currentUrl.includes('/user/reservations') ? 'text-orange-600' : 'text-gray-500'}
                `}>
                    <FaRegCalendarAlt size={20} className=''/>
                    <p className='text-sm'>{text['bookings'][l]}</p>
                </div>
                <div
                    onClick={() => router.push('/user/messages')} 
                    className={`w-full h-full flex flex-col gap-1 items-center justify-center cursor-pointer pt-1
                    ${currentUrl.includes('/user/messages') ? 'text-orange-600' : 'text-gray-500'}
                `}>
                    <LuMessageSquare size={20} className=''/>
                    <p className='text-sm'>{text['messages'][l]}</p>
                </div>
                <div
                    onClick={() => router.push('/user/settings')} 
                    className={`w-full h-full flex flex-col gap-1 items-center justify-center cursor-pointer pt-1
                    ${currentUrl.includes('/user/settings') ? 'text-orange-600' : 'text-gray-500'}
                `}>
                    <PiGearBold size={20} className=''/>
                    <p className='text-sm'>{text['settings'][l]}</p>
                </div>

            </nav>
        </div>
    )
}