
interface CardTagProps {
  text: string;
}

export const CardTag = ({
  text
}:CardTagProps) => {
  

  return (
    <div className="w-fit pb-1.5 pt-2.5 px-3 bg-gray-200 rounded-full">
      <p 
        className="text-sm text-gray-800 leading-none "
        style={{
          fontFamily: 'G-Hind'
        }}
        >{text}
      </p>
    </div>
  )
}


